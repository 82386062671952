<template>
  <v-container :fluid="true" class="synapsa-container system-container">
    <!-- LOGS BUTTONS -->
    <v-row
      v-if="inLogs"
      align="center"
      justify="center"
      class="mx-0 log-tabs-row"
    >
      <v-col cols="12" class="px-0 py-2 log-tabs">
        <v-btn
          small
          text
          :loading="logLoading"
          :color="''"
          class="text-capitalize synapsa-tab log-tab mr-2"
          @click="exportLog()"
        >
          Download
          <v-avatar class="ml-1" size="18">
            <svg
              id="Vrstva_1"
              data-name="Vrstva 1"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 46 38"
            >
              <defs></defs>
              <path
                class="cls-1"
                d="M362.62,450.89h1c5.8,0,10-4.87,10-10.87a10.69,10.69,0,0,0-10.5-10.86h-.06a8.95,8.95,0,0,0-17.71-1,7.21,7.21,0,0,0-3.73-1,7.63,7.63,0,0,0-7.5,7.76,8.25,8.25,0,0,0,.24,1.94,7.23,7.23,0,0,0-4.74,6.85,7.13,7.13,0,0,0,7,7.25h4"
                transform="translate(-328.62 -419.89)"
              />
              <polyline class="cls-1" points="23 20 23 37 29 31" />
              <line class="cls-1" x1="23" y1="37" x2="17" y2="31" />
            </svg>
          </v-avatar>
        </v-btn>
        <v-btn
          small
          text
          :loading="clearLogLoading"
          :color="''"
          class="text-capitalize synapsa-tab log-tab"
          @click="clearLogDialog()"
        >
          Clear Data
          <v-avatar class="ml-1" size="18">
            <svg
              id="Vrstva_1"
              data-name="Vrstva 1"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 46 36.24"
            >
              <defs></defs>
              <path
                class="cls-1"
                d="M362.62,451.76h1c5.8,0,10-4.86,10-10.86A10.68,10.68,0,0,0,363.12,430h-.06a8.95,8.95,0,0,0-17.71-1,7.21,7.21,0,0,0-3.73-1,7.63,7.63,0,0,0-7.5,7.76,8.25,8.25,0,0,0,.24,1.94,7.22,7.22,0,0,0-4.74,6.85,7.12,7.12,0,0,0,7,7.24h4"
                transform="translate(-328.62 -420.76)"
              />
              <line class="cls-1" x1="27.24" y1="26.76" x2="18.76" y2="35.24" />
              <line class="cls-1" x1="27.24" y1="35.24" x2="18.76" y2="26.76" />
            </svg>
          </v-avatar>
        </v-btn>

        <v-dialog
            v-model="deleteDataDialog"
            persistent
            max-width="350"
        >
          <v-card>
            <v-card-title class="text-h5" >
              Clear Data
            </v-card-title>

            <v-card-text class="red--text center">Please be careful. Erasing data can corrupt the application.</v-card-text>
            <v-container>
              <v-row>
                <v-col
                    cols="12"
                >
                  <div class="toggle ml-4">
                    <v-switch
                    v-model="formEvents"
                    :input-value="true"
                    label="Events"
                    hide-details
                    inset
                    :class="[formEvents ? 'v-switch-on' : 'v-switch-off']"
                    class="target-background v-switch-off"
                    />
                  </div>
                  <div class="toggle ml-4">
                    <v-switch
                    v-model="formSystemlog"
                    :input-value="true"
                    label="Systemlogs"
                    hide-details
                    inset
                    :class="[formSystemlog ? 'v-switch-on' : 'v-switch-off']"
                    class="target-background v-switch-off"
                    />
                  </div>
                  <div class="toggle ml-4">
                    <v-switch
                    v-model="formSyslog"
                    :input-value="true"
                    label="Syslog"
                    hide-details
                    inset
                    :class="[formSyslog ? 'v-switch-on' : 'v-switch-off']"
                    class="target-background v-switch-off"
                    />
                  </div>
                </v-col>
              </v-row>
            </v-container>

            <v-card-text>Confirm to clear your data.</v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                  color="green darken-1"
                  text
                  @click="deleteDataDialog = false"
              >
                Cancel
              </v-btn>
              <v-btn
                  color="green darken-1"
                  text
                  @click="clearTables"
              >
                Delete Data
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>

      </v-col>
    </v-row>
    <!-- LOGS BUTTONS END -->
    <!-- TASK BUTTONS -->
    <v-row
      v-if="inTasks"
      align="center"
      justify="center"
      class="mx-0 log-tabs-row"
    >
      <v-col cols="12" class="px-0 py-2 log-tabs">
        <v-btn
          @click="exportTask()"
          small
          text
          :loading="exportTaskLoading"
          :color="''"
          class="text-capitalize synapsa-tab log-tab mr-2"
        >
          Download Tasks
        </v-btn>
        <v-btn
          type="file"
          small
          text
          :loading="importTaskLoading"
          :color="''"
          class="text-capitalize synapsa-tab log-tab"
          @click="upload()"
        >
          Import Task
        </v-btn>
        <!-- hidden file input -->
        <input
          ref="uploader"
          class="d-none"
          type="file"
          accept="application/JSON"
          @change="onFileChanged"
        />
      </v-col>
    </v-row>
    <!-- TASK BUTTONS END -->
    <v-row align="center" justify="center" class="mx-0">
      <v-col cols="12" class="px-2 py-2 synapsa-tabs">
        <v-btn
          v-for="tab in tabs"
          v-permission="tab.permissions"
          :key="tab.id"
          :text="!tab.ids.includes(activeTab)"
          :color="tab.ids.includes(activeTab) ? 'primary' : ''"
          :outlined="tab.ids.includes(activeTab)"
          small
          class="text-capitalize synapsa-tab"
          :class="tab.ids.includes(activeTab) ? 'elevation-1' : ''"
          @click="to(tab)"
        >
          {{ $t(tab.name) }}
        </v-btn>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" class="pt-1">
        <router-view @selectAssigned="assignValue($event)" />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { clearSysLog, clearSystemLog, clearEvents, exportLogData } from "@/api/system/logs";
import { taskImport, taskExport } from "@/api/system/task";
import { Errors } from "@/utils/error";
import permission from "@/directive/permission/permission.js";
export default {
  directives: { permission },
  data() {
    return {
      selectedFile: null,
      hover: false,
      location: "",
      inLogs: false,
      inTasks: false,
      activeTab: "backup",
      selectValue: "today",
      logLoading: false,
      clearLogLoading: false,
      exportTaskLoading: false,
      importTaskLoading: false,
      deleteDataDialog: false,
      formEvents: false,
      formSystemlog: false,
      formSyslog: false,
      tabs: [
        {
          id: "backup",
          ids: ["backup","backupConfiguration","backupAll","backupRemote"],
          name: "system.tab.backup",
          route: "/system/show/backup",
          permissions: ["view backup"],
        },
        {
          id: "logs",
          ids: ["logs"],
          name: "system.tab.logs",
          route: "/system/show/logs",
          permissions: ["view log"],
        },
        {
          id: "tasks",
          ids: ["tasks"],
          name: "system.tab.tasks",
          route: "/system/show/tasks",
          permissions: ["view task"],
        },
        {
          id: "users",
          ids: ["users"],
          name: "system.tab.users",
          route: "/system/show/users",
          permissions: ["view user"],
        },
        {
          id: "roles",
          ids: ["roles"],
          name: "system.tab.roles",
          route: "/system/show/roles",
          permissions: ["view role"],
        },
        {
          id: "version",
          ids: ["version"],
          name: "system.tab.version",
          route: "/system/show/version",
          permissions: ["view version"],
        },
        {
          id: "license",
          ids: ["license"],
          name: "system.tab.license",
          route: "/system/show/license",
          permissions: ["view license"],
        },
        {
          id: "ssl",
          ids: ["ssl"],
          name: "system.tab.ssl",
          route: "/system/show/ssl",
          permissions: ["view ssl"],
        },
        {
          id: "synapsa",
          ids: ["synapsa"],
          name: "system.tab.synapsa",
          route: "/system/show/synapsa",
          permissions: ["view synapsa"],
        },
        {
          id: "dns",
          ids: ["dns"],
          name: "system.tab.dns",
          route: "/system/show/dns",
          permissions: ["view dns"],
        },
        {
          id: "maintenance",
          ids: ["maintenance"],
          name: "system.tab.maintenance",
          route: "/system/show/maintenance",
          permissions: ["view maintenance"],
        },
        {
          id: "login",
          ids: ["login"],
          name: "system.tab.login",
          route: "/system/show/login",
          permissions: ["view login"],
        },
      ],
      icons: {
        name: "image.jpg",
      },
    };
  },
  computed: {
    changeInAddForm: {
      get() {
        return this.$store.state.settings.changeInAddForm;
      },
      set(val) {
        this.$store.dispatch("settings/changeSetting", {
          key: "changeInAddForm",
          value: val,
        });
      },
    },
  },
  watch: {
    $route: {
      immediate: true,
      deep: true,
      handler(newValue) {
        this.inLogs = false;
        this.inTasks = false;
        if (newValue.name === "logs") {
          this.inLogs = true;
        } else if (newValue.name === "tasks") {
          this.inTasks = true;
        }

        this.activeTab = newValue.name;
      },
    },
  },
  created() {
    this.location = window.location.origin;
  },
  methods: {
    /* LOGS */
    assignValue(logParameter) {
      this.selectValue = "";
      this.selectValue = logParameter.value;
    },
    clearLogDialog() {
      this.clearFormData();
      this.deleteDataDialog = true;
    },
    clearSyslogTable() {
      this.clearLogLoading = true;
      clearSysLog()
          .then((response) => {
            this.$toast.open({
              message: response.message,
              type: response.type,
              position: "top-right",
              duration: 2000,
            });
          })
          .catch((e) => {
            this.$toast.open({
              message: e.response
                  ? e.response.status + " - Data loading failed!"
                  : e,
              type: "error",
              position: "top-right",
              duration: 2000,
            });
            Errors.show(e);
          });
      this.clearLogLoading = false;
    },
    clearSystemLogTable() {
      this.clearLogLoading = true;
      clearSystemLog()
          .then((response) => {
            this.$toast.open({
              message: response.message,
              type: response.type,
              position: "top-right",
              duration: 2000,
            });
          })
          .catch((e) => {
            this.$toast.open({
              message: e.response
                  ? e.response.status + " - Data loading failed!"
                  : e,
              type: "error",
              position: "top-right",
              duration: 2000,
            });
            Errors.show(e);
          });
      this.clearLogLoading = false;
    },
    clearEventsTable() {
      this.clearLogLoading = true;
      clearEvents()
          .then((response) => {
            this.$toast.open({
            message: response.message,
            type: response.type,
            position: "top-right",
            duration: 2000,
            });
          })
          .catch((e) => {
            this.$toast.open({
              message: e.response
                  ? e.response.status + " - Data loading failed!"
                  : e,
              type: "error",
              position: "top-right",
              duration: 2000,
            });
            Errors.show(e);
          });
      this.clearLogLoading = false;
    },
    exportLog() {
      const data = {
        log: this.selectValue,
      };
      this.logLoading = true;
      exportLogData(data)
        .then((response) => {
          this.logLoading = false;
          var fileURL = window.URL.createObjectURL(
            new Blob([response], { type: "application/zip" })
          );
          var fileLink = document.createElement("a");
          fileLink.href = fileURL;
          const date = this.getDate();
          fileLink.setAttribute("download", "logs_" + date + ".zip");
          document.body.appendChild(fileLink);
          fileLink.click();
        })
        .catch((e) => {
          this.logLoading = false;
          Errors.show(e);
        });
    },
    getDate() {
      const today = new Date();
      let min = today.getMinutes();
      let hh = today.getHours();
      let dd = today.getDate();
      let mm = today.getMonth() + 1;
      let yyyy = today.getFullYear();

      if (min < 10) {
        min = "0" + min;
      }
      if (hh < 10) {
        hh = "0" + hh;
      }
      if (dd < 10) {
        dd = "0" + dd;
      }
      if (mm < 10) {
        mm = "0" + mm;
      }
      return dd + mm + yyyy + "_" + hh + min;
    },
    /* LOGS END */
    /* TASKS */
    onFileChanged(e) {
      this.selectedFile = e.target.files[0];
      let formData = new FormData();
      if (this.selectedFile) {
        formData.append("tasks", this.selectedFile);
        this.importTaskLoading = true;
        taskImport(formData)
          .then((response) => {
            this.importTaskLoading = false;
            this.$toast.open({
              message: response.message,
              type: response.message.type,
              position: "top-right",
              duration: 2000,
            });
            this.changeInAddForm = "systemtask";
          })
          .catch((e) => {
            this.importTaskLoading = false;
            Errors.show(e);
          });
      }
    },
    upload() {
      this.$refs.uploader.click();
    },
    exportTask() {
      this.exportTaskLoading = true;
      taskExport()
        .then((response) => {
          this.exportTaskLoading = false;
          var fileURL = window.URL.createObjectURL(
            new Blob([response], { type: "application/json" })
          );
          var fileLink = document.createElement("a");
          fileLink.href = fileURL;
          const date = this.getDate();
          fileLink.setAttribute("download", "tasks_" + date + ".json");
          document.body.appendChild(fileLink);
          fileLink.click();
        })
        .catch((e) => {
          this.exportTaskLoading = false;
          Errors.show(e);
        });
    },
    /* TASKS END */
    to(tab) {
      this.$router.push(tab.route);
      this.activeTab = tab.id;
    },
    /* Clear Database tables */
    clearTables() {
      this.deleteDataDialog = false
      if(this.formEvents){
        this.clearEventsTable();
      }
      if(this.formSystemlog){
        this.clearSystemLogTable();
      }
      if(this.formSyslog){
        this.clearSyslogTable();
      }

     // Set all fo false
      this.clearFormData();
    },
    clearFormData() {
      this.formEvents = false;
      this.formSystemlog = false;
      this.formSyslog = false;
    },
  },
};
</script>
<style lang="scss" scoped>
$synapsaGreen: #69be28;

.log-tabs-row {
  margin-bottom: -32px;
}
.log-tabs {
  margin-top: 20px;
  margin-bottom: 20px;
  .theme--dark.v-btn:hover,
  .theme--light.v-btn:hover {
    color: $synapsaGreen;

    .cls-1 {
      fill: none;
      stroke: $synapsaGreen;
      stroke-linecap: round;
      stroke-linejoin: round;
      stroke-width: 2px;
    }
  }
}

.v-application.theme--light {
  .log-tab {
    background: #ffffff6e;
    box-shadow: 13px 13px 30px rgb(0 0 0 / 10%);
  }
}

.v-application.theme--dark {
  .log-tab {
    background: #1e1e1e96;
    box-shadow: 13px 13px 30px rgb(0 0 0 / 10%);
  }
}
.v-application.theme--dark {
  .cls-1 {
    transition: 0.3s;
    fill: none;
    stroke: #fff;
    stroke-linecap: round;
    stroke-linejoin: round;
    stroke-width: 2px;
  }
}

.v-application.theme--light {
  .cls-1 {
    transition: 0.3s;
    fill: none;
    stroke: #363636;
    stroke-linecap: round;
    stroke-linejoin: round;
    stroke-width: 2px;
  }
}
</style>
<style lang="scss" >
.system-container{
  .v-data-table__wrapper {
    border-radius: 15px;
}
}
</style>
