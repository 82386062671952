import request from "@/utils/request";

export function clearSysLog(data) {
  return request({
    url: "api/system/log/clearsyslog",
    method: "post",
    data: data,
  });
}

export function clearSystemLog(data) {
  return request({
    url: "api/system/log/clearsystemlog",
    method: "post",
    data: data,
  });
}

export function clearEvents(data) {
  return request({
    url: "api/system/log/clearevents",
    method: "post",
    data: data,
  });
}

export function exportLogData(data) {
  return request({
    url: "api/system/log/exportdata",
    method: "post",
    data: data,
    responseType: "blob",
  });
}
